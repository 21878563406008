

/* Global Styles */
/* تعریف فونت Handjet-Medium */
@font-face {
    font-family: 'Handjet-Medium';
    src: url('./font/Handjet-Medium.ttf') format('truetype'); /* آدرس فایل فونت */
    font-weight: normal; /* وزن فونت */
    font-style: normal; /* سبک فونت */
}

/* تعریف فونت Handjet-Bold */
@font-face {
    font-family: 'Handjet-Bold';
    src: url('./font/Handjet-Bold.ttf') format('truetype'); /* آدرس فایل فونت */
    font-weight: normal; /* وزن فونت */
    font-style: normal; /* سبک فونت */
}
@font-face {
    font-family: 'Alexandria';
    src: url('./font/Alexandria.ttf') format('truetype'); /* آدرس فایل فونت */
    font-weight: normal; /* وزن فونت */
    font-style: normal; /* سبک فونت */
}

/* تعریف فونت CustomFont */
@font-face {
    font-family: 'CustomFont';
    src: url('./font/font001.ttf') format('truetype'); /* آدرس فایل فونت */
    font-weight: normal; /* وزن فونت */
    font-style: normal; /* سبک فونت */
}

/* تعریف فونت Changa */
@font-face {
    font-family: 'Changa';
    src: url('./font/Changa-Medium.ttf') format('truetype'); /* آدرس فایل فونت */
    font-weight: normal; /* وزن فونت */
    font-style: normal; /* سبک فونت */
}

/* تعریف فونت FontdinerSwanky */
@font-face {
    font-family: 'FontdinerSwanky';
    src: url('./font/FontdinerSwanky.ttf') format('truetype'); /* آدرس فایل فونت */
    font-weight: normal; /* وزن فونت */
    font-style: normal; /* سبک فونت */
}

/* تعریف فونت Rubik-Light */
@font-face {
    font-family: 'Rubik-Light';
    src: url('./font/Rubik-Light.ttf') format('truetype'); /* آدرس فایل فونت */
    font-weight: normal; /* وزن فونت */
    font-style: normal; /* سبک فونت */
}

/* تعریف فونت Barriecito-Regular */
@font-face {
    font-family: 'Barriecito-Regular';
    src: url('./font/Barriecito-Regular.ttf') format('truetype'); /* آدرس فایل فونت */
    font-weight: normal; /* وزن فونت */
    font-style: normal; /* سبک فونت */
}

/* تنظیمات عمومی برای بدنه */
body {
    min-height: 100vh; /* حداقل ارتفاع برای پوشش کل صفحه */
    margin: 0; /* حذف حاشیه‌های پیش‌فرض */
    overflow-y: auto; /* فعال کردن اسکرول عمودی */
    font-family: 'CustomFont', Arial, sans-serif; /* استفاده از فونت سفارشی */
    background-color: #000; /* تنظیم رنگ پس‌زمینه */
}

/* تنظیمات برای کلاس app */
.app {
    display: flex; /* استفاده از flexbox برای چیدمان */
    flex-direction: column; /* چیدمان عمودی */
    justify-content: flex-start; /* تراز کردن عناصر به سمت بالا */
    align-items: center; /* تراز کردن عناصر در وسط */
    background-image: url('./pic/snake.svg'); /* تصویر پس‌زمینه */
    background-size: cover; /* پوشش کامل تصویر */
    background-repeat: no-repeat; /* عدم تکرار تصویر */
    background-position: center; /* مرکز قرار دادن تصویر */
    min-height: 100vh; /* ارتفاع کامل صفحه */
    position: relative; /* فعال کردن موقعیت‌دهی */
    padding: 10px; /* افزودن حاشیه برای جلوگیری از چسبیدن محتوا به لبه‌ها */
}

/* استایل برای متن با افکت نئونی */
.neon-text {
    color: #006400; /* رنگ سبز تیره */
    text-shadow: 
        0 0 5px #32CD32, /* سایه سبز روشن */
        0 0 10px #32CD32, /* سایه سبز روشن */
        0 0 15px #32CD32, /* سایه سبز روشن */
        0 0 20px #32CD32, /* سایه سبز روشن */
        0 0 30px #32CD32; /* سایه سبز روشن */
}

/* تنظیمات برای لاگ رویدادها */
.event-log {
    height: 7vh; /* تنظیم ارتفاع حداکثر */
    overflow-y: auto; /* فعال کردن اسکرول عمودی */
    width: 300px; /* عرض کامل */
    background-color: rgba(0, 0, 0, 0.7); /* پس‌زمینه نیمه شفاف */
    color: #fff; /* رنگ متن سفید */
    padding: 10px; /* افزودن حاشیه */
    border-radius: 5px; /* گوشه‌های گرد */
}

/* استایل‌های tooltip */
.tooltip {
    visibility: hidden; /* مخفی بودن به‌طور پیش‌فرض */
    width: 120px; /* عرض tooltip */
    background-color: rgba(0, 0, 0, 0.0); /* پس‌زمینه نیمه شفاف */
    color: #fff; /* رنگ متن سفید */
    text-align: center; /* تراز متن در وسط */
    border-radius: 5px; /* گوشه‌های گرد */
    padding: 5px; /* افزودن حاشیه */
    position: absolute; /* موقعیت‌دهی مطلق */
    bottom: 120%; /* موقعیت بالای عنصر */
    left: 50%; /* تراز به وسط */
    transform: translateX(-50%); /* جابجایی به سمت چپ برای مرکز کردن */
    z-index: 10; /* قرار دادن در بالای سایر عناصر */
	transition: all 0.2s ease;
}


.buy-antidote-container:hover .tooltip {
    visibility: visible !important; /* اضافه کردن !important برای جایگزینی مقادیر inline */
    opacity: 1 !important;
}
.social-button:hover .tooltip,
.donate-button:hover .donate-tooltip {
    visibility: visible; /* نمایش tooltip هنگام هاور */
}

/* استایل‌های دکمه‌ها */
button {
    margin: 10px; /* حاشیه */
    padding: 10px 20px; /* حاشیه داخلی */
    font-size: 24px; /* اندازه فونت */
    cursor: pointer; /* تغییر نشانگر به دست */
    background-color: rgba(0, 0, 0, 0.0); /* پس‌زمینه نیمه شفاف */
    border: none; /* حذف حاشیه */
    border-radius: 30px; /* گوشه‌های گرد */
    color: red; /* رنگ متن */
    transition: background-color 0.3s; /* انیمیشن تغییر رنگ پس‌زمینه */
}

/* تغییر رنگ پس‌زمینه دکمه هنگام هاور */
button:hover {
    background-color: rgba(255, 255, 255, 0.0); /* رنگ پس‌زمینه شفاف */
}

/* تنظیمات دکمه‌های اجتماعی */
.social-buttons {
    display: flex; /* استفاده از flexbox برای چیدمان */
    justify-content: center; /* تراز کردن به وسط */
    align-items: center; /* تراز کردن عمودی */
    position: flex; 
    bottom: 20px; /* فاصله از پایین */
    left: 50%; /* تراز به وسط */
    transform: translateX(0%); /* جابجایی به سمت چپ برای مرکز کردن */
}

/* اندازه آیکون‌های اجتماعی */
.social-icon {
    width: 30px; /* عرض آیکون */
    height: 30px; /* ارتفاع آیکون */
    margin: 0 10px; /* حاشیه */
    cursor: pointer; /* تغییر نشانگر به دست */
}

/* تنظیمات دکمه‌های اهدایی */
.donate-button {
    position: relative; /* فعال کردن موقعیت‌دهی */
    margin: 0 5px; /* حاشیه */
}

/* اندازه آیکون دکمه‌های اهدایی */
.donate-icon {
    width: 30px; /* عرض آیکون */
    height: 30px; /* ارتفاع آیکون */
    cursor: pointer; /* تغییر نشانگر به دست */
}

/* تنظیمات tooltip دکمه‌های اهدایی */
.donate-tooltip {
    visibility: hidden; /* مخفی بودن به‌طور پیش‌فرض */
    width: 220px; /* عرض tooltip */
    background-color: rgba(0, 0, 0, 0.0); /* پس‌زمینه نیمه شفاف */
    color: #fff; /* رنگ متن سفید */
    text-align: center; /* تراز متن در وسط */
    border-radius: 5px; /* گوشه‌های گرد */
    padding: 5px; /* افزودن حاشیه */
    position: absolute; /* موقعیت‌دهی مطلق */
    bottom: 40px; /* موقعیت بالای عنصر */
    left: 50%; /* تراز به وسط */
    transform: translateX(-50%); /* جابجایی به سمت چپ برای مرکز کردن */
    z-index: 1; /* قرار دادن در بالای سایر عناصر */
}

/* نشان دادن tooltip دکمه‌های اهدایی هنگام هاور */
.donate-button:hover .donate-tooltip {
    visibility: visible; /* نمایش tooltip هنگام هاور */
}



/* استایل برای دکمه اتصال کیف پول */
.wallet-connect-button {
    background-color: transparent; /* پس‌زمینه شفاف */
    border: 2px solid #32CD32; /* حاشیه سبز */
    border-radius: 50%; /* گرد کردن دکمه */
    padding: 15px; /* حاشیه داخلی */
    cursor: pointer; /* نشانگر دست */
    transition: background-color 0.3s, transform 0.3s; /* انیمیشن تغییر رنگ و اندازه */
    position: relative; /* فعال کردن موقعیت‌دهی */
    color: #32CD32; /* رنگ متن */
    font-size: 16px; /* اندازه فونت */
}

/* تغییر رنگ پس‌زمینه و حاشیه هنگام هاور */
.wallet-connect-button:hover {
    background-color: rgba(50, 205, 50, 0.1); /* رنگ پس‌زمینه نیمه شفاف هنگام هاور */
    transform: scale(1.05); /* بزرگ کردن دکمه هنگام هاور */
}

/* افکت نئونی هنگام هاور */
.how-to-play-button:hover {
    background-color: rgba(46, 139, 87, 0.2); /* پس‌زمینه سبز تیره نیمه شفاف */
	    border: 2px solid #32CD32; /* حاشیه سبز */

    transform: scale(1.1); /* بزرگ کردن دکمه */
    box-shadow: 0 0 20px #2E8B57, 0 0 30px #2E8B57, 0 0 40px #2E8B57; /* سایه نئونی */
}

/* استایل برای آیکون */
.how-to-play-icon {
    width: 40px; /* عرض آیکون */
    margin-right: 5px; /* حاشیه سمت راست */
}

/* تنظیمات برای مودال راهنمای بازی */
.how-to-play-modal {
    position: fixed; /* موقعیت ثابت برای ماندن در وسط صفحه */
    top: 50%; /* موقعیت عمودی وسط صفحه */
    left: 50%; /* موقعیت افقی وسط صفحه */
    transform: translate(-50%, -50%); /* مرکزسازی دقیق */
    background-color: rgba(0, 0, 0, 0.9); /* پس‌زمینه تیره با شفافیت */
    color: #32CD32; /* رنگ متن سبز */
    padding: 20px; /* حاشیه داخلی */
    border-radius: 10px; /* گوشه‌های گرد */
    z-index: 1000; /* قرار دادن در بالای سایر عناصر */
    max-height: 80vh; /* حداکثر ارتفاع 80% از ارتفاع صفحه */
    width: 60%; /* عرض 90% از صفحه */
    overflow-y: auto; /* فعال‌سازی اسکرول عمودی */
    border: none; /* حاشیه سبز */
    box-shadow: 0 0 100px #32CD32; /* سایه نئونی سبز */
    font-family: 'CustomFont', Arial, sans-serif; /* فونت سفارشی */
}

/* سفارشی‌سازی نوار اسکرول */
.how-to-play-modal::-webkit-scrollbar {
    width: 10px; /* عرض اسکرول بار */
}

.how-to-play-modal::-webkit-scrollbar-thumb {
    background: #32CD32; /* رنگ سبز برای قسمت متحرک اسکرول بار */
    border-radius: 10px; /* گوشه‌های گرد */
    border: 2px solid #006400; /* حاشیه تیره */
	
}

.how-to-play-modal::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.3); /* پس‌زمینه نیمه شفاف برای اسکرول بار */
    border-radius: 10px; /* گوشه‌های گرد */
}

/* استایل عنوان مودال */
.how-to-play-modal h2 {
    color: #FFD700; /* رنگ عنوان طلایی */
    font-family: 'Changa', sans-serif; /* فونت سفارشی */
    text-align: center; /* متن در وسط */
    margin-bottom: 1px; /* فاصله از پایین */
    font-size: 24px; /* اندازه فونت */
}

/* استایل متن مودال */
.how-to-play-modal p {
    line-height: 1.6; /* فاصله خطی برای خوانایی بهتر */
    margin-bottom: 15px; /* فاصله از پایین */
    font-size: 18px; /* اندازه فونت */
    color: #fff; /* رنگ متن سفید */
}

.how-to-play-modal p.fa {
    font-family: 'Alexandria', sans-serif; /* فونت فارسی */
    font-size: 18px; /* اندازه فونت */
    line-height: 1.6; /* فاصله خطی */
    color: #fff; /* رنگ متن */
    text-align: right; /* تراز متن به راست */
}

/* استایل دکمه بستن مودال */
.how-to-play-modal button {
    background-color: #32CD32; /* پس‌زمینه سبز */
    color: #000; /* رنگ متن مشکی */
    padding: 1px 1px; /* حاشیه داخلی */
    border: none; /* حذف حاشیه */
    border-radius: 5px; /* گوشه‌های گرد */
    cursor: pointer; /* نشانگر دست */
    float: right; /* قرار دادن در سمت راست */
    transition: all 0.3s; /* انیمیشن تغییرات */
    font-family: 'CustomFont', Arial, sans-serif; /* فونت سفارشی */
}

/* افکت هاور برای دکمه بستن */
.how-to-play-modal button:hover {
    background-color: #228B22; /* تغییر رنگ پس‌زمینه */
    box-shadow: 0 0 10px #32CD32; /* سایه نئونی */
}


.messages-log {
    margin-top: 20px;
    width: 100%;
    max-width: 600px;
}

.message {
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    border-left: 5px solid #32CD32; /* رنگ سبز برای حاشیه */
}
.life-time-container {
    width: 35%; /* عرض کامل */
    margin: 20px 0; /* حاشیه بالا و پایین */
}

.life-time-bar {
    position: relative;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
}

.life-time-progress {
    height: 100%;
    background-color: #32CD32;
    transition: width 0.5s ease;
}

.time-counter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000;
    font-size: 12px;
    font-weight: bold;
    pointer-events: none; /* عدم تداخل با کلیک */
    z-index: 1;
}

/* کلاس loading-container */
.loading-container {
    position: fixed; /* فیکس کردن موقعیت */
    top: 50%; /* قرار دادن در وسط عمودی */
    left: 50%; /* قرار دادن در وسط افقی */
    transform: translate(-50%, -50%); /* جابجایی دقیق به مرکز */
    z-index: 1000; /* اطمینان از اینکه بالای سایر عناصر قرار گیرد */
    background-color: rgba(0, 0, 0, 0.7); /* پس‌زمینه نیمه‌شفاف */
    padding: 20px; /* فاصله داخلی */
    border-radius: 100px; /* گوشه‌های گرد */
    text-align: center; /* تراز متن در وسط */
}

/* استایل تصویر لودینگ */
.loading-gif {
    width: 250px; /* اندازه تصویر */
    height: auto; /* نسبت طول به عرض حفظ شود */
    margin-bottom: 10px; /* فاصله از جمله */
}

/* استایل متن لودینگ */
.loading-text {
    color: #fff; /* رنگ متن سفید */
    font-size: 16px; /* اندازه فونت */
    font-family: 'CustomFont', Arial, sans-serif; /* فونت سفارشی */
}