/* src/GameBoard.css */
.App {
    text-align: center;
    display: flex; /* استفاده از flexbox برای چیدمان */
    align-items: center; /* تراز کردن عناصر در وسط */
    min-height: 100vh; /* ارتفاع کامل صفحه */
    position: relative; /* فعال کردن موقعیت‌دهی */
    padding: 10px; /* افزودن حاشیه برای جلوگیری از چسبیدن محتوا به لبه‌ها */
}

.board {
    display: grid;
    grid-template-columns: repeat(10, minmax(40px, 8vmin)); /* اندازه خانه‌ها بر اساس vmin */
    grid-template-rows: repeat(10, minmax(40px, 8vmin)); /* اندازه خانه‌ها بر اساس vmin */
    margin: 5px auto;
    width: fit-content;
    background-image: url('./snake.svg');
    background-size: cover;
    background-position: center;
    position: relative;
    height: fit-content; /* ارتفاع کامل صفحه */
    max-width: 90%; /* محدودیت حداکثر عرض برای پاسخگویی */
    aspect-ratio: 1 / 1; /* حفظ نسبت طول به عرض */
}

.square {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    position: relative;
}

.player-image {
    width: 99%; /* اندازه مهره نسبت به خانه */
    height: 99%; /* اندازه مهره نسبت به خانه */
    position: absolute; /* موقعیت‌دهی دقیق درون خانه */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none; /* جلوگیری از اینکه مهره روی کلیک‌ها تأثیر بگذارد */
    z-index: 10; /* اطمینان از اینکه مهره بالای عدد خانه قرار گیرد */
}

.square-number {
    display: block;
    font-size: 14px; /* اندازه فونت شماره‌ها */
    color: black;
    z-index: 1;
    visibility: hidden; /* نامرئی کردن شماره‌ها */
}

.square.active {
    background-color: rgba(76, 175, 80, 0.2); /* رنگ پس‌زمینه برای خانه فعال */
}

button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 14px;
}

/* Media Queries برای پشتیبانی از صفحات کوچکتر */
@media (max-width: 768px) {
    .board {
        grid-template-columns: repeat(10, minmax(30px, 6vmin)); /* کاهش اندازه خانه‌ها */
        grid-template-rows: repeat(10, minmax(30px, 6vmin));
    }

    .player-image {
        width: 50%; /* کاهش اندازه مهره */
        height: 50%;
    }
}

@media (max-width: 480px) {
    .board {
        grid-template-columns: repeat(10, minmax(20px, 4vmin)); /* کاهش بیشتر اندازه خانه‌ها */
        grid-template-rows: repeat(10, minmax(20px, 4vmin));
    }

    .player-image {
        width: 40%; /* کاهش بیشتر اندازه مهره */
        height: 40%;
    }
}