@keyframes roll {
    0% {
        transform: rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: rotateX(900deg) rotateY(900deg); /* چرخش کامل در هر دو محور */
    }
    100% {
        transform: rotateX(0deg) rotateY(180deg);
    }
}

#dice {
    width: 100px; /* عرض تاس */
    height: 100px; /* ارتفاع تاس */
    transform-style: preserve-3d; /* حفظ بعد 3D */
    animation: roll 4s infinite; /* انیمیشن دائمی */
    border-radius: 10px; /* گوشه‌های گرد */
}

.sides {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #EFE5DC;
    border: 2px solid black;
    border-radius: 10px; /* گوشه‌های گرد */
    display: flex; /* فعال کردن Flexbox */
    justify-content: center; /* مرکز کردن افقی */
    align-items: center; /* مرکز کردن عمودی */
    font-size: 2rem; /* اندازه متن */
}

.dice-symbol {
    font-size: 3rem; /* اندازه علامت */
    color: black; /* رنگ علامت */
    line-height: 1; /* جلوگیری از فاصله اضافی */
    margin: 0; /* حذف حاشیه */
}

#dice .side-1 { transform: translateZ(50px); }
#dice .side-2 { transform: rotateY(90deg) translateZ(50px); }
#dice .side-3 { transform: rotateY(180deg) translateZ(50px); }
#dice .side-4 { transform: rotateY(-90deg) translateZ(50px); }
#dice .side-5 { transform: rotateX(90deg) translateZ(50px); }
#dice .side-6 { transform: rotateX(-90deg) translateZ(50px); }